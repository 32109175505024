import { IConsent } from "src/types/IConsent";
import { IEvent } from "src/types/IEvent";
import { IIncentivizedAction } from "src/types/IIncentivizedAction";
import { IPoiStatusData } from "src/types/IPoiStatusData";
import { User } from "src/types/User";
import { Config } from "../context/ConfigContext";
import { IApplePaySessionObject } from "../types/IApplePaySessionObject";
import { IDealsData } from "../types/IDealsData";
import { IChangePayment, INewPayment } from "../types/INewPayment";
import { IPoiPageData } from "../types/IPoiPageData";
import PoiStatus from "../types/PoiStatus";
import { SummaryData } from "../types/SummaryData";
import ServerAxios from "./serverAxios";
import { ISubscription } from "src/types/ISubscribtion";
import { SurveyProvider } from "@eqengage/system-enums";
import axios, { AxiosResponse } from 'axios';


import {
  AddUpoApmRequest,
  AddUpoApmResponse,
  SessionTokenForZeroAuthRequest,
  SessionTokenResponse,
} from "src/types/Nuvei";
import { Transaction } from "src/types/Transaction";
import ConsoleAxios from "./consoleAxios";
export async function getApplePaySessionToken(
  validationUrl: string,
  debtId?: string
): Promise<IApplePaySessionObject> {
  const { data } = await ServerAxios.get(
    `/api/applePaySessionToken/${debtId}?validationUrl=${validationUrl}`
  );
  return data;
}

export async function getDynamicContentContext(
  runDebtId: string
): Promise<void> {
  const { data } = await ServerAxios.get(`/api/contentcontext/${runDebtId}`);
  return data;
}

export async function getDisclosureContent(
  clientCode: string
): Promise<string> {
  const { data } = await ServerAxios.get(`/external/disclosureContent/${clientCode}`);
  return data;
}

export async function enteredPortal(debtId: string): Promise<void> {
  await ServerAxios.post(`/login/portal/${debtId}`);
}

export async function newPayment(body: INewPayment): Promise<void> {
  return ServerAxios.post(`/api/submitPayment`, body);
}

export async function change_PaymentMethod(body: IChangePayment): Promise<void> {
  return ServerAxios.post(`/api/change_PaymentMethod`, body);
}

export async function getNuveiSessionTokenForZeroAuth(
  body: SessionTokenForZeroAuthRequest
): Promise<SessionTokenResponse> {
  const { data } = await ServerAxios.post(
    `/api/getNuveiSessionTokenForZeroAuth`,
    body
  );
  return data;
}

export async function addNuveiUpoApm(
  body: AddUpoApmRequest
): Promise<AddUpoApmResponse> {
  const { data } = await ServerAxios.post(`/api/addNuveiUpoApm`, body);
  return data;
}

export async function getDealsData(debtId: string): Promise<IDealsData> {
  const { data } = await ServerAxios.get(`/api/deals/${debtId}`);
  return data;
}

export async function getPoiPageData(debtId: string): Promise<IPoiPageData> {
  const { data } = await ServerAxios.get(`/login/poiPage/${debtId}`);
  return data;
}

export async function getPortalConfig(debtId: string): Promise<Config> {
  const { data } = await ServerAxios.get(`/login/config/${debtId}`);
  return data;
}

export async function getClientLanguages(
  clientCode: string
): Promise<{ lang: string; isDefault: boolean }[]> {
  const { data } = await ServerAxios.get(`/login/client/${clientCode}/languages`);
  return data;
}

export async function getClientConfig(
  clientCode: string
): Promise<{ lang: string; isDefault: boolean }[]> {
  const { data } = await ServerAxios.get(`/login/client/${clientCode}/config`);
  return data;
}

export async function submitPoi(
  runDebtId: string,
  poi: string,
  poiFormat: string,
  allowMobileMessages: boolean
): Promise<{ status: PoiStatus }> {
  const response = await ServerAxios.post(`/login/submitPoi`, {
    runDebtId,
    poi,
    poiFormat,
    allowMobileMessages,
  });
  return response.data;
}

export async function submitReferenceNumber(
  referenceNumber: string
): Promise<string> {
  const response = await ServerAxios.post(`/login/referenceNumber`, {
    referenceNumber,
  });
  return response.data;
}

export async function getReferenceNumber(
  referenceNumber: string
): Promise<string> {
  const response = await ServerAxios.post(`/login/getReferenceNumber`, {
    referenceNumber,
  });
  return response.data;
}

export async function getDebtStatus(
  runDebtId: string
): Promise<IPoiStatusData | void> {
  const { data } = await ServerAxios.get(`/login/status/${runDebtId}`);
  return data;
}

export async function getTransactionByRunDebtId(
  runDebtId: string
): Promise<any> {
  const { data } = await ServerAxios.get(`/login/transaction/status/${runDebtId}`);
  return data;
}

export async function authValidate(): Promise<IPoiStatusData | void> {
  const { data } = await ServerAxios.get(`/auth/validate`);
  return data;
}

export async function getSummaryData(
  debtId: string
): Promise<SummaryData | void> {
  const { data } = await ServerAxios.get(`/api/summary/${debtId}`);
  return data;
}

export async function sendEvent(event: IEvent): Promise<void> {
  const { data } = await ServerAxios.post(`/event/send`, event);

  return data;
}

export async function recordCustomerConsent(body: IConsent): Promise<void> {
  return ServerAxios.post(`/login/customerConsent`, body);
}

export async function getIncentivizedActionData(runDebtId: string): Promise<{
  incetivizedActionData: IIncentivizedAction;
  user: User;
}> {
  const { data } = await ServerAxios.get(`/api/incetivizedAction/${runDebtId}`);
  return data;
}

export async function postIncentivizedAction(runDebtId: string): Promise<void> {
  return ServerAxios.post(`/api/incetivizedAction/${runDebtId}`);
}

export async function getIncentivizedFormData(
  runDebtId: string
): Promise<object | undefined> {
  const { data } = await ServerAxios.get(
    `/api/incetivizedFormData/${runDebtId}`
  );
  return data;
}

export async function recordCustomerIncentivizedConsent(
  runDebtId: string,
  body: IConsent
): Promise<void> {
  return ServerAxios.post(`/api/incetivizedAction/${runDebtId}/consent`, body);
}

export async function updateSubscription(
  runDebtId: string,
  body: ISubscription
): Promise<void> {
  return ServerAxios.post(`/subscribe/${runDebtId}`, body);
}

export async function startIncentivizedActionEvent(
  runDebtId: string,
  surveyProviderId: SurveyProvider
): Promise<void> {
  return ServerAxios.post(`/api/incetivizedAction/start/${runDebtId}`, {
    surveyProvider: surveyProviderId,
  });
}

export async function getNextIncentivizedAction(
  runDebtId: string
): Promise<void> {
  return ServerAxios.get(`/api/incetivizedAction/next/${runDebtId}`);
}

export async function updateDebtLanguage(
  runDebtId: string,
  selectedLanguage: string
): Promise<void> {
  return ServerAxios.post(`/login/debt/updateDebtLanguage/${runDebtId}`, {
    selectedLanguage,
  });
}

export async function createPaymentOrder(
  data:any
): Promise<AxiosResponse<any>> {
  return await ServerAxios.post(`/api/mymoid/payment-order`, data);
}

export async function getRunTimeConfig(): Promise<AxiosResponse<any>> {
  return await ServerAxios.get(`/runtime-env.js`);
}

// const CONSTANT_TEXT_API =
//   window.__RUNTIME_CONFIG__.URL_LINGUAL_SERVICE;

// export async function contactConsentAPI(
//   dcaId?: string,
//   buId?: string
// ): Promise<AxiosResponse<any>> {
//   const res = await axios.post(`http://localhost:3010/engage-shell-service/api/customers/action/addcontactopt`);
//   return res.data;
// }
  
export async function contactConsentAPI(
  reqBody:any
): Promise<AxiosResponse<any>> {
  const { data } = await ConsoleAxios.post(`/customers/action/addcontactopt`, reqBody);
  return data;
}
